<template>
  <div class="viedo">

    <Head recruit="true"></Head>
    <div class="main">
      <video class="video" :src="videoUrl" muted="muted" preload="preload" controls="controls">
      </video>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Head from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
export default {
  name: 'viedo',
  data() {
    return {
      videoUrl: ''
    };
  },
  components: {
    Head,
    Footer,
  },
  created() {
    this.videoUrl = this.$route.query.videoUrl
  },
  mounted() {

  },
  methods: {

  },
};
</script>
<style scoped lang="scss">
.main {
  width: 1200px;
  margin: 0 auto;
  height: calc(100vh - 280px);
  padding: 20px 0;

  .video {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    object-fit: cover;
  }
}
</style>
